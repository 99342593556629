.jb-progress-bar {
  display: flex;
  background: #E0E0E0;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 0;
}

.jb-progress-bar__bar {
  flex: 0;
  background: #333;
}
