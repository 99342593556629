.ui.table.jb-ordered-table {
  margin-bottom: 0;
}

.ui.table.jb-ordered-table thead th {
  background: none;
}

.jb-ordered-table__more {
  font-size: 12px;
  padding: 0 8px;
}
