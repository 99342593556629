.ui.accordion.jb-analytics__accordion:not(:last-child) {
  margin-bottom: 20px;
}

.ui.accordion.jb-analytics__accordion .title .dropdown.icon {
  margin-top: 4px;
}

.jb-analytics__action {
  float: right;
  margin-right: 22px;
  text-transform: none;
}
