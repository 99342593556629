.jb-groups {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.jb-groups__group {
  display: flex;
  min-width: 240px;
  align-items: center;
  margin: 24px 0;
}

.jb-groups__pie {
  background: #E0E0E0;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  line-height: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-family: 'EuclidCircularA-SemiBold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 24px;
  border: 0 solid #000;
}

.jb-groups__group--active .jb-groups__pie {
  border-width: 2px;
}

.jb-groups__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 32px;
  font-family: 'Raleway', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  min-width: 150px;
}

.jb-groups__info a {
  font-size: 12px;
  line-height: 1.6;
}

.jb-groups__label {
  margin-left: -18px;
}

.jb-groups__group:hover .jb-groups__info a {
  text-decoration: underline;
}
