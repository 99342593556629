.jb-doughnut {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .jb-doughnut {
    flex-wrap: wrap;
  }
}

.jb-doughnut__chart {
  width: 157px;
  padding: 0 16px;
}

.jb-doughnut__legends {
  padding: 0 16px;
}

.jb-doughnut__legends table {
  font-weight: bold;
  line-height: 22px;
  font-size: 14px;
}

.jb-doughnut__legends td {
  padding: 0 4px;
  vertical-align: middle;
  color: #616161;
}

.jb-doughnut__value {
  font-size: 20px;
  line-height: 30px;
}

.canvasjs-chart-credit {
  display: none;
}
