@font-face {
    font-family: 'EuclidCircularA-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-Bold.woff') format('woff');
}

@font-face {
    font-family: 'EuclidCircularA-SemiBold';
    font-style: normal;
    font-weight: normal;
    src: url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'EuclidCircularA-Medium';
    font-style: normal;
    font-weight: normal;
    src: url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-Medium.woff') format('woff');
}


